import { jwtDecode } from "jwt-decode";
import { checkEmailValidation, emailRegex, EVENT_TIMESTAMP_KEY, excludeEmails, MeasurementRowOptions, secondEmailRegex, SOMETHING_WENT_WRONG, TODAY_DATE } from "./Constants";
import { COMBINE_EVENTS_EQUATION, DATE_PICKER_VALUE, MEASUREMENT_CONVERSION_TYPE } from "enum/enums";
import { v4 as uuidv4 } from "uuid";

export const handleErrorMessage = (error: any) => {
	return error?.response?.data?.error ?? error?.response?.data?.message ?? error?.response?.data?.details?.[0]?.message ?? SOMETHING_WENT_WRONG;
};

export const isValidEmail = (email: string): boolean => {
	return checkEmailValidation.test(email);
};

export function JwtTokenValidate(jwtToken = "") {
	const token = jwtToken || getLocalStorageData("JWTToken");

	if (token) {
		try {
			const decodedToken: any = jwtDecode(token);
			const currentDate = new Date();
			if (decodedToken.exp * 1000 < currentDate.getTime()) {
				return false;
			}
			return decodedToken;
		} catch (err) {
			console.log(err);
		}
	}
}
export function isValidStoreUrl(url:string) {
	const httpsUrlPattern = /^https:\/\/[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-z]{2,})(\/.*)?$/;
	return httpsUrlPattern.test(url);
}

let id: NodeJS.Timeout;
export const copyToClipboard = (text: string, buttonId = "copy-button",afterText="Copied to clipboard",preText="Copy to clipboard") => {
	return new Promise((resolve, reject) => {
		clearTimeout(id);
		navigator.clipboard.writeText(text).then(
			function () {
				resolve("copied");
				const button = document.getElementById(buttonId);
				if (button) {
					button.innerText = afterText;
					id = setTimeout(() => {
						button.innerText = preText;
					}, 2000);
				}
			},
			function (err) {
				console.error("Async: Could not copy text: ", err);
				reject(err);
			},
		);
	});
};
export const checkStoreUrl = (storeUrl: string) => {
	const regex = /\.myshopify\.com/;
	return regex.test(storeUrl);
};
export const removeLocalStorage = (key: string) => {
	localStorage.removeItem(key);
};
export function getLocalStorageData(key: string) {
	try {
		return JSON.parse(localStorage.getItem(key) ?? "");
	} catch {
		return null;
	}
}

export function setLocalStorageData(key: string, value: any) {
	localStorage.setItem(key, JSON.stringify(value));
}

export function formatDate(timestamp: string | number) {
	const date = new Date(timestamp);
	const monthAbbreviation = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date);
	const day = date.getUTCDate();
	const year = date.getUTCFullYear();
	const formattedDate = `${monthAbbreviation}-${day}-${year}`;
	return formattedDate;
}

export function getDate(date = TODAY_DATE) {
	const currentDate = new Date(date);
	const year = currentDate.getFullYear();
	const month = currentDate.getMonth() + 1;
	const day = currentDate.getDate();
	const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}`;
	return formattedDate;
}

export const getFilteredSizingSuggestionListLength = (data: any[], store_url: string) => {
	return data?.filter((item: any) => {
		const keys = Object.keys(item.properties);
		let res = false;
		keys.forEach((key) => {
			if (item.properties[key]["store_url"]) {
				res = item.properties[key].store_url === store_url;
				return;
			}
		});
		return res;
	}).length;
};

export const removeUnwantedEmails = (data: any[]) => {
	return data?.filter((el) => !emailRegex.test(el?.properties?.email) && !secondEmailRegex.test(el?.properties?.email) && !excludeEmails.includes(el?.properties?.email) && el?.person);
};

export const kpiCalculation = (numerator = 0, denominator = 1, percentageType = false) => {
	// console.log("--- numerator", numerator, denominator)
	if (!numerator || !denominator) {
		return 0;
	}
	const result = numerator / denominator || 0;
	if (result && result !== Infinity) {
		// console.log("here-------result", result,percentageType)
		return percentageType ? `${parseFloat((result * 100).toFixed(2))}%` : parseFloat((result).toFixed(2));
	// return percentageType ? `${Math.round(result * 100)}%` : Math.round(result);
	}
	return percentageType ? `${0}%` : 0;
};

export const getEventCountSum = (data: any[]) => {
	return data?.reduce((acc, el) => {
		if(el?.count){
			const count = typeof el?.count === "number" ? el.count : +el.count;
			return acc + (count ?? 0);

		}
		return acc + (el?.count ?? 0);
	}, 0);
};
export function formatTime(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.round(seconds % 60); // Rounds to the nearest second

    const formattedMinutes = minutes > 0 ? `${minutes}m ` : "";
    const formattedSeconds = `${remainingSeconds}s`;

    return formattedMinutes + formattedSeconds;
}


export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export function formatDateForMetric(offsetDays: DATE_PICKER_VALUE | string): string {
	let daysToGoBack = 6;
	switch (offsetDays) {
		case DATE_PICKER_VALUE.FOURTEEN_DAYS: {
			daysToGoBack = 13;
			break;
		}
		case DATE_PICKER_VALUE.THIRTY_DAYS: {
			daysToGoBack = 29;
			break;
		}
		case DATE_PICKER_VALUE.THIS_QUATER: {
			daysToGoBack = 119;
			break;
		}
		case DATE_PICKER_VALUE.SEVEN_DAYS: {
			daysToGoBack = 6;
			break;
		}
		default: {
			return getMonthStart(new Date());
		}
	}
	const currentDate: Date = new Date();
	currentDate.setDate(currentDate.getDate() - daysToGoBack);
	const year: number = currentDate.getFullYear();
	const month: string = String(currentDate.getMonth() + 1).padStart(2, "0");
	const day: string = String(currentDate.getDate()).padStart(2, "0");
	return `${year}-${month}-${day}`;
}
export function getMonthStart(currentDate: Date): string {
	const year: number = currentDate.getFullYear();
	const month: number = currentDate.getMonth();
	const monthStart: Date = new Date(year, month, 1);
	const formattedYear: number = monthStart.getFullYear();
	const formattedMonth: string = String(monthStart.getMonth() + 1).padStart(2, "0");
	const formattedDay: string = String(monthStart.getDate()).padStart(2, "0");

	return `${formattedYear}-${formattedMonth}-${formattedDay}`;
}
export function getDaysSinceMonthStart(currentDate: Date): number {
	const monthStart: Date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
	const timeDifference: number = currentDate.getTime() - monthStart.getTime();
	const dayDifference: number = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
	return dayDifference;
}

export const CombineEvents = (data1: any[], data2: any[], type: COMBINE_EVENTS_EQUATION): any[] => {
	let mergedDataMap: any[] = [];
	let data: any[] = [];
	switch (type) {
		case COMBINE_EVENTS_EQUATION.ADD: {
			data = [...data1, ...data2];
			mergedDataMap = Object.values(
				data?.reduce((acc, item) => {
					const date = item[EVENT_TIMESTAMP_KEY]?.split("T")?.[0];
					if (!acc[date]) {
						acc[date] = { ...item };
					} else {
						acc[date].count += item.count;
					}
					return acc;
				}, {}),
			);
			break;
		}
		case COMBINE_EVENTS_EQUATION.DIVIDE: {
			if (!data1.length || !data2.length) {
				return [];
			}
			data = [...data1, ...data2];
			mergedDataMap = Object.values(
				data?.reduce((acc, item) => {
					const date = item[EVENT_TIMESTAMP_KEY]?.split("T")?.[0];
					if (!acc[date]) {
						acc[date] = { ...item };
					} else {
						acc[date].count = isFinite(acc[date].count / item.count) ? +(acc[date].count / item.count).toFixed(2) : 0;
					}
					return acc;
				}, {}),
			);
		}
	}
	return sortEventData(mergedDataMap);
};

export const extractDataFromEvents = (data: any, type = "group"): any[] => {
	switch (type) {
		case "single": {
			const keys = Object.keys(data?.dailyMetrics);
			return keys.reduce((acc: any[], item: string) => {
				const payload = {
					[EVENT_TIMESTAMP_KEY]: item,
					count: data?.dailyMetrics?.[item]?.averageOrderValue || 0,
				};
				acc.push(payload);
				return acc;
			}, []);
		}
		default: {
			return (
				data?.reduce((acc: any, item: any) => {
					Object.keys(item).forEach((el) => {
						if (el === "on_date") {
							return acc;
						}
						if (!acc[el]) {
							acc[el] = [];
						}
						acc[el].push({ [EVENT_TIMESTAMP_KEY]: item.on_date, count: item?.[el] ?? item?.price });
					});
					return acc;
				}, {}) || {}
			);
		}
	}
};

export const sortEventData = (data: any[]) => {
	return data?.sort((a, b) => new Date(b[EVENT_TIMESTAMP_KEY]).getTime() - new Date(a[EVENT_TIMESTAMP_KEY]).getTime()) || [];
};

export const generateUuid = () => {
	return uuidv4();
};
export function camelCaseToWords(s: string) {
	const result = s.replace(/([A-Z])/g, " $1");
	return result.charAt(0).toUpperCase() + result.slice(1);
}

export const deepCopy = (value: any) => {
	if (value) {
		return JSON.parse(JSON.stringify(value));
	}
};
export const scrollToBottom = (id: string) => {
	const element = document.getElementById(id);
	if (element) {
		element?.scrollIntoView({
			behavior: "smooth",
		});
	}
};
interface MakeMeasurementRowSelected {
	options: string;
	rowOptions: any[];
	beRowOptions?: string[];
	key?: string;
	key2?: string;
	value?: boolean;
	beValues?: Record<string, any>;
}

export const makeMeasurementRowSelected = ({ options, rowOptions = [], beRowOptions = [""], key = "", key2 = "", beValues = {} }: MakeMeasurementRowSelected) => {
	switch (options) {
		case "keysMatch": {
			beRowOptions.forEach((el) => {
				const index = rowOptions.findIndex((elem) => elem.name === el);
				if (index !== undefined || index !== -1) {
					rowOptions[index].selected = !!beValues[rowOptions[index]?.name];
				}
			});
			return rowOptions;
		}
		case "techpack": {
			return rowOptions?.reduce((acc: { name: string; id: string; selected: boolean }[], el: { name: string; id: string; selected: boolean }) => {
				const index = beRowOptions?.some((elem: any) => beValues[elem][el?.name]);
				if (index) {
					el.selected = true;
				}
				acc.push(el);
				return acc;
			}, []);
		}
		case "selection": {
			return rowOptions.map((el) => {
				if (el.name === key) {
					el.selected = !el.selected;
				}
				if (el.name === key2) {
					el.selected = !el.selected;
				}
				return el;
			});
		}
		default: {
			for (let index = 0; index < 3; index++) {
				rowOptions[index].selected = true;
			}
			return rowOptions;
		}
	}
};
interface ModifyMeasurementRow {
	key: string;
	rowArray?: { name: string; id: string }[];
	measurementData?: Record<string, any>[];
	rowOptions?: any[];
	keys?: string[];
	measurementList?: Record<string, Record<string, string>>;
}
export const modifyMeasurementRow = ({ key, rowArray, measurementData, rowOptions, keys, measurementList }: ModifyMeasurementRow) => {
	switch (key) {
		case "noData": {
			if (measurementList && Object.keys(measurementList).length) {
				Object.entries(measurementList).forEach(([key, value]: any) => {
					if (value?.action !== "skip") {
						rowArray?.push({ name: key, id: generateUuid() });
					}
				});
			} else {
				for (let index = 0; index < 3; index++) {
					rowArray?.push({ name: MeasurementRowOptions[index].name, id: generateUuid() });
				}
			}

			return rowArray;
		}
		case "techpack": {
			return rowOptions?.reduce((acc: { name: string; id: string; selected: boolean }[], el: { name: string; id: string; selected: boolean }) => {
				const index = keys?.some((elem: any) => measurementData?.[elem]?.[el?.name]);
				if (index) {
					acc.push(el);
				}
				return acc;
			}, []);
		}
		default: {
			if (measurementData) {
				Object.entries(measurementData).forEach(([key, val]: [string, any]) => {
					if (val != null) {
						rowArray?.push({ name: key, id: generateUuid() });
					}
				});
			}
			return rowArray;
		}
	}
};

export const getColumnsDataTechpack = (data: Record<string, object>, isEase = {}): { name: string; id: string; center: boolean }[] => {
	const arr = [{ name: "Measurement", id: uuidv4(), center: false }];
	if (Object.keys(isEase)?.length) {
		arr.push({ name: "ease", id: uuidv4(), center: true });
	}
	if (!data) {
		return arr;
	}
	Object.keys(data).forEach((el) => {
		arr.push({ name: el, id: uuidv4(), center: true });
	});
	return arr;
};

export const measurementConversion = (value: number, type: MEASUREMENT_CONVERSION_TYPE) => {
	if (value === null || value === undefined || value === 0) {
		return 0;
	}
	switch (type) {
		case MEASUREMENT_CONVERSION_TYPE.CM: {
			return value * 2.54;
		}
		case MEASUREMENT_CONVERSION_TYPE.INCH: {
			return value / 2.54;
		}
	}
};

export const measurmentDataMod = (data: any, type: any) => {
	switch (type) {
		case MEASUREMENT_CONVERSION_TYPE.CM: {
			return Object.entries(data).reduce((acc: any, [key, value]: [key: string, value: any]) => {
				if (value) {
					acc[key] = value ? limitToSevenDigits(+measurementConversion(+value, MEASUREMENT_CONVERSION_TYPE.CM).toFixed(2)) : 0;
				}
				return acc;
			}, {});
		}
		case MEASUREMENT_CONVERSION_TYPE.INCH: {
			return Object.entries(data).reduce((acc: any, [key, value]: [key: string, value: any]) => {
				if (value) {
					acc[key] = value ? limitToSevenDigits(+measurementConversion(+value, MEASUREMENT_CONVERSION_TYPE.INCH).toFixed(2)) : value;
				}
				return acc;
			}, {});
		}
	}
};
export function limitToSevenDigits(num: number) {
	if (num === null || num === undefined) {
		return 0;
	}
	const numStr: string = num.toString();
	if (numStr.length <= 7) {
		return parseFloat(numStr);
	}
	if (!numStr.includes(".")) {
		return parseFloat(numStr.slice(0, 7));
	}
	const [integerPart, decimalPart] = numStr.split(".");
	const totalLength: number = integerPart.length + decimalPart.length + 1; // +1 for the decimal point
	if (totalLength <= 7) {
		return parseFloat(numStr);
	}
	const maxDecimalLength = 6 - integerPart.length;
	return parseFloat(`${integerPart}.${decimalPart.slice(0, maxDecimalLength)}`);
}

export function sumOfNumbersFromId(id: string) {
	const numbers = id.match(/\d/g);
	const sum = numbers ? numbers.map(Number).reduce((acc, curr) => acc + curr, 0) : 0;
	return sum;
}

export const getConfirmedMeasurements = (list: any) => {
	return Object.entries(list).reduce((acc: any, [key, value]: any) => {
		if (value?.action !== "skip") {
			acc.push({
				name: value?.measurementName,
				value: key,
				id: key,
				definition: value?.definition,
			});
		}
		return acc;
	}, []);
};

export const fileToBase64 = (file: File) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			resolve(reader.result);
		};

		reader.onerror = (error) => {
			reject(error);
		};
	});
export const createReviewData = (data: Record<string, string>, productName: string) => {
	if (data && Object.keys(data).length) {
		return [{ name: productName, isFinetune: false, imageUrls: Object.values(data), feedback: "" }];
	}
	return [];
};
export function dateFormat(inputDate: string): string {
	const date = new Date(inputDate);

	const options: Intl.DateTimeFormatOptions = {
		day: "numeric",
		month: "short",
		year: "numeric",
	};

	return date.toLocaleDateString("en-GB", options);
}
export const customMeasurementValue = (data: Record<string, any>) => {
	delete data["isMeasured"];
	const array = Object.keys(data) ?? [];
	// const length = Math.floor(array?.length / 2);
	const arrData:{ id: string; name: string; value: any }[] = [];
	// const secondData:{ id: string; name: string; value: any }[] = [];
	array?.forEach((el) => {
		const id = uuidv4();
		const value = {
			id,
			name: el,
			value: data[el],
		};
		arrData.push(value)
		// if (index % 2 === 0) {
		// 	firstData.push(value);
		// } else {
		// 	secondData.push(value);
		// }
	});

	return arrData;
};
